import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { Button } from "react-bootstrap";
import { SafeHtmlParser } from "../components/safeHtmlParser";
import uuid4 from "uuid4";

const GetInvolvedPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				pageData: wpPage(slug: { eq: "get-involved" }) {
					seoFields {
						title
						metaDescription
						opengraphTitle
						opengraphDescription
						localBusinessSchema
						image {
							node {
								sourceUrl
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
										original {
											width
											height
										}
									}
								}
							}
						}
					}
					getInvolvedPageFieldGroups {
						getInvolvedSection {
							getInvolvedList {
								heading
								description
								cta {
									title
									target
									url
								}
							}
						}
					}
				}
			}
		`
	);

	const {
		pageData: {
			seoFields,
			getInvolvedPageFieldGroups: { getInvolvedSection },
		},
	} = data;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Get Involved",
				item: {
					url: `${siteUrl}/get-involved`,
					id: `${siteUrl}/get-involved`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/get-involved`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: "Get involved with Holding Space",
						},
					],
				}}
			/>
			{getInvolvedSection && getInvolvedSection.getInvolvedList && (
				<section className="py-5">
					<Container>
						{getInvolvedSection.getInvolvedList.map((item) => (
							<div key={uuid4()}>
								<Row>
									<Col>
										<h1 className="text-primary">{item?.heading}</h1>
										<SafeHtmlParser htmlContent={item?.description} />
									</Col>
								</Row>
								{item.cta && item.cta?.url && (
									<Row className="mb-5">
										<Col lg={4}>
											<Button
												variant="info"
												className="full-width-button"
												as={Link}
												to={item.cta?.url}
												target={item.cta?.target ?? "_blank"}
											>
												{item.cta?.title}
											</Button>
										</Col>
									</Row>
								)}
							</div>
						))}
					</Container>
				</section>
			)}
		</Layout>
	);
};
export default GetInvolvedPage;
